import axios from 'axios';
import { consola } from 'consola';

const logger = consola.withTag('page-not-found');

class BffService {
  constructor() {
    this.pages = [];
    this.pageContent = [];
    this.services = {};
    /* https://mpl-bff-dot-parkos-production.appspot.com/ */
    this.axiosInstance = axios.create({
      baseURL: process.env.BFF_BASE_URL,
    });

    this.axiosInstance.interceptors.request.use(
      (request) => {
        const { url: path, baseURL, params } = request;
        const url = new URL(path, baseURL);
        logger.info(`BFF Request: ${url.toString()}`, params ?? '');

        return request;
      },
      (error) => {
        logger.error('BFF Request Error: ', JSON.stringify(error));
        return Promise.reject(error);
      },
    );
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        logger.error('BFF Response Error:  ', JSON.stringify(error));
        return Promise.reject(error);
      },
    );
    this.refreshes = {
      pageContent: false,
      services: false,
    };
  }

  refresh() {
    this.refreshes.pageContent = true;
    this.refreshes.services = true;
  }

  async getPageContent(devtitle, lang) {
    try {
      const res = await this.axiosInstance.get(`pages/${devtitle}/content.json`, {
        params: {
          lang,
        },
      });
      return res.data;
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  getService(service) {
    if (this.services[service] && !this.refreshes.services) {
      return this.services[service];
    }
    this.refreshes.services = false;
    const self = this;
    return new Promise((resolve, reject) => {
      self.axiosInstance
        .get(`services/${service}`)
        .then((response) => {
          self.services[service] = response.data;
          resolve(self.services[service]);
        })
        .catch((e) => {
          console.error('Error in getService:', e);
          reject(e);
        });
    });
  }

  async getHeaderAirportsConfiguration() {
    const { axiosInstance } = this;
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('configurations/header')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error in getHeaderAirportsConfiguration:', error);
          reject(error);
        });
    });
  }

  async getLanguages(limit = 50) {
    try {
      const { data } = await this.axiosInstance.get('languages', {
        params: {
          limit,
        },
      });
      return data.data;
    } catch (error) {
      console.error('Error in getLanguages:', error);
      throw error;
    }
  }

  async getTranslations(language) {
    try {
      const { data } = await this.axiosInstance.get(`translations/${language}/404`);
      return data;
    } catch (error) {
      console.error('Error in getTranslations:', error);
      throw error;
    }
  }

  async getAirports(lang, limit = 250, orderBy = 'locations_content.name') {
    try {
      const { data } = await this.axiosInstance.get('airports', {
        params: {
          lang,
          limit,
          orderBy,
        },
      });
      return data.data;
    } catch (error) {
      console.error('Error in getAirports:', error);
      throw error; // rethrow the error to propagate it up the call stack
    }
  }

  async getPageTemplate(slug, lang) {
    try {
      const { data } = await this.axiosInstance.get('page_template', {
        params: {
          slug,
          lang,
        },
      });
      return data?.data[lang];
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getPageNotFoundContent(lang) {
    try {
      const { data } = await this.axiosInstance.get('static-pages', {
        params: {
          slug: '404',
          lang,
        },
      });
      return data?.find((content) => content.language === lang);
    } catch (error) {
      console.error('Error in getPageNotFoundContent:', error);
      throw error;
    }
  }

  async getOpeningTimes(language) {
    try {
      const { data } = await this.axiosInstance.get('customer-service/opening-times.json', {
        params: {
          lang: language,
        },
      });
      return data;
    } catch (e) {
      console.log(e);
      return e;
    }
  }
}

const bffInstances = {};

function getInstance(name, config) {
  if (!(name in bffInstances)) {
    bffInstances[name] = new BffService(config);

    setInterval(() => {
      bffInstances[name].refresh();
    }, 60000);
  }

  return bffInstances[name];
}

export { getInstance }; // eslint-disable-line import/prefer-default-export
