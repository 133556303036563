var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded),expression:"expanded"}],staticClass:"absolute z-40 w-24 rounded-md top-8"},[_c('div',[_c('div',{staticClass:"airport-picker__modal__caret inset-x-1/2 absolute bg-silver-100 border-t border-l transform rotate-45 -translate-y-1/2 border-gray-200 z-10"}),_vm._v(" "),_c('div',{staticClass:"airport-picker__modal__header md:bg-silver-100 border-y rounded-t-lg md:py-2.5 flex flex-col min-h-max shrink-0"},[_vm._m(0),_vm._v(" "),_c('div',{ref:"country",staticClass:"airport-picker__modal__header__countries__country font-bold font-sans cursor-pointer py-3 px-4 whitespace-nowrap overflow-hidden flex-shrink-0"},[_vm._v("\n        "+_vm._s(_vm.$i18n('general.time'))+"\n      ")])])]),_vm._v(" "),_c('div',{ref:"timePicker",staticClass:"legacy-time-picker-desktop max-h-64 rounded-b-md border-athens border shadow-dropdown overflow-y-scroll overscroll-contain"},[_vm._l((_vm.times),function(time){return [_c('div',{key:time.value,class:[
          'legacy-time-picker-desktop__item px-4 py-0.5 font-bold font-sans',
          {
            'legacy-time-picker-desktop__item--selected bg-orange-500 text-white': time.value === _vm.selectedValue,
            'bg-white hover:bg-orange-500 hover:text-white': time.value !== _vm.selectedValue,
          },
        ],attrs:{"role":"button","tabindex":"0"},on:{"keypress":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectionChanged(time.value)},"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectionChanged(time.value)}}},[_vm._v("\n        "+_vm._s(time.title)+"\n      ")])]})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:hidden relative h-12 w-full bg-white place-content-end flex min-h-max"},[_c('div',{staticClass:"inline-block relative my-auto right-2 z-10 w-5 h-5 p-5 ml-auto"},[_c('div',{staticClass:"absolute inset-y-1/2 inset-x-3 h-0.5 bg-black-alt-300 rounded origin-center transform rotate-45"}),_vm._v(" "),_c('div',{staticClass:"absolute inset-y-1/2 inset-x-3 h-0.5 bg-black-alt-300 rounded origin-center transform -rotate-45"})])])
}]

export { render, staticRenderFns }