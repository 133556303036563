<template>
  <!-- Main container -->
  <div class="relative" @click.stop>
    <!-- Calendar toggle button -->
    <slot name="date" :toggle="toggleCalendar" :showCalendar="isExpanded" />

    <component
      v-if="isExpanded"
      :is="$deviceDetector.isMobile() ? 'Mobile' : 'Desktop'"
      :showCalendar="isExpanded"
      :minDate="minDate"
      :defaultTime="defaultTime"
      :date="date"
      @selectDate="selectDate"
      @collapseDate="collapseDate"
      :dateOther="dateOther"
    />
  </div>
</template>

<script>
export default {
  components: {
    Desktop: () => import('./DesktopCalendar.vue'),
    Mobile: () => import('./MobileCalendar.vue'),
  },
  props: ['minDate', 'defaultTime', 'date', 'dateOther'],
  data() {
    return {
      isExpanded: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.collapseDate);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.collapseDate);
  },
  methods: {
    collapseDate() {
      this.isExpanded = false;
      this.$emit('collapsed', this.isExpanded);
    },
    expand() {
      this.isExpanded = true;
      this.$emit('expanded');
    },
    toggleCalendar() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) {
        this.$emit('expanded');
      } else {
        this.$emit('collapsed', this.isExpanded);
      }
    },
    selectDate(selectedDate) {
      this.isExpanded = false;
      this.$emit('collapsed', this.isExpanded);
      this.$emit('dateChanged', selectedDate);
    },
  },
};
</script>
