import { getInstance } from '~/services/bffService';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
export const state = () => ({
  languages: [],
  language: {},
  airports: [],
  inactiveAirports: [],
  airport: {},
  totalParkings: 0,
  translations: {},
  pageContent: {},
  modalOpen: false,
  trustpilotUrls: {},
  headerAirportsConfiguration: {},
  zendeskEnabled: false,
  pageNotFoundContent: null,
  openingTimes: {},
});

export const mutations = {
  setModal(state, value) {
    state.modalOpen = value;
  },
};

function assignDataForDABucket(state, bucketState) {
  // domain
  bucketState.domain.country = state.language.country.name;
  bucketState.domain.country_code = state.language.country.code;
  bucketState.domain.language = state.language.lang;
  bucketState.domain.name = state.language.domain;
  //
  bucketState.currency = state.language.currency.iso_code;
}

function getLanguage(lang) {
  const languageMap = new Map([['pseudo_fr_airfrance', 'fr']]);

  if (languageMap.has(lang)) {
    return languageMap.get(lang);
  }

  return lang;
}

export const actions = {
  async nuxtServerInit({ state }, { app }) {
    const bff = getInstance('parkos');

    state.languages = await bff.getLanguages();
    state.languages = state.languages.map((language) => ({
      ...language,
      lang: getLanguage(language.lang),
    }));

    state.language = state.languages.find((language) => language.domain === app.$paths.host);

    state.openingTimes = await bff.getOpeningTimes(state.language.lang);

    const airports = await bff.getAirports(state.language?.lang ?? 'en-us');
    state.airports = airports.filter(
      (airport) => !!state.language?.status && (!!state.language.is_minimal_product || airport.parking_count > 0),
    );

    const { lang } = state.language;

    if (!state.language.socials.facebook) {
      delete state.language.socials.facebook;
    }

    if (!state.language.socials.twitter) {
      delete state.language.socials.twitter;
    }

    // If no YouTube link is set in the socials fallback to the default
    if (!state.language.socials.youtube) {
      state.language.socials.youtube = 'https://www.youtube.com/parkosnl';
    }

    const promises = [
      bff.getTranslations(state.language.lang),
      ...['terms-conditions', 'privacy-policy', 'about-us', 'faq'].map((slug) =>
        bff.getPageContent(slug, state.language.lang),
      ),
      bff.getService('trustpilot'),
      bff.getService('zendesk_whitelist'),
      bff.getHeaderAirportsConfiguration(),
      ...['footer_airports', 'footer_information', 'footer_about', 'footer_icons'].map((slug) =>
        bff.getPageTemplate(slug, state.language.lang),
      ),
      bff.getPageNotFoundContent(state.language.lang),
    ];
    const [
      translations,
      termsContent,
      privacyPolicyContent,
      aboutContent,
      faqMeta,
      trustpilotUrls,
      zendeskWhitelist,
      headerAirportsConfiguration,
      airportsTemplate,
      informationTemplate,
      aboutTemplate,
      paymentIconsTemplate,
      pageNotFoundContent,
    ] = await Promise.all(promises);

    state.pageNotFoundContent = pageNotFoundContent?.page_content;
    state.translations = translations;

    state.pageContent = {
      home: pageNotFoundContent,
      termsConditionsContent: termsContent[lang],
      privacyPolicyContent: privacyPolicyContent[lang],
      aboutPageContent: aboutContent[lang],
    };
    // console.log('🚀 ~ file: index.js:149 ~ zendeskBlacklist:', zendeskBlacklist);

    state.trustpilotUrls = trustpilotUrls;
    state.zendeskEnabled = zendeskWhitelist || zendeskWhitelist?.includes(app.$paths.host);
    state.modalOpen = false;

    state.headerAirportsConfiguration = headerAirportsConfiguration;

    state.pageTemplate = {
      footer_airports: airportsTemplate,
      footer_information: informationTemplate,
      footer_about: aboutTemplate,
      footer_icons: paymentIconsTemplate,
    };
    state.metaPages = {
      faqMeta: faqMeta[state.language.lang] || undefined,
    };

    assignDataForDABucket(state, state.bucket);
  },
};

export const strict = false;
