<template>
  <div class="overflow-x-hidden leading-relative">
    <Highlight />
    <Header />
    <main>
      <Nuxt />
    </main>
    <client-only>
      <noscript>
        <!-- Tag Manager -->
        <iframe
          :src="`https://www.googletagmanager.com/ns.html?id=${language.extra_gtm_key}&noscript=`"
          height="0"
          width="0"
          style="display: none; visibility: hidden"
        ></iframe>
        <!-- ./Tag Manager -->

        <!-- CHEQ Tag -->
        <iframe
          v-if="cheqTagScript"
          :src="`https://obseu.segreencolumn.com/ns/${cheqTagScript.scriptFileName}.html?ch=${language.domain}`"
          width="0"
          height="0"
          style="display: none"
        ></iframe>
        <!-- ./CHEQ Tag -->
      </noscript>
    </client-only>
    <LazyHydrate when-visible>
      <Footer />
    </LazyHydrate>
  </div>
</template>

<script>
import Header from '~/components/header/Header.vue';
import Highlight from '~/components/Highlight.vue';
import Footer from '~/components/footer/Footer.vue';
import LazyHydrate from 'vue-lazy-hydration';

export default {
  components: {
    LazyHydrate,
    Highlight,
    Header,
    Footer,
  },
  head() {
    const script = [];

    if (this.language.extra_gtm_key) {
      const attrs = {
        hid: 'ExtraGTM',
        src: `https://www.googletagmanager.com/gtag/js?id=${this.language.extra_gtm_key}`,
        async: true,
      };
      script.push(attrs);
    }

    if (this.cheqTagScript) {
      const tagAttributes = {
        hid: 'CHEQ',
        src: `https://euob.segreencolumn.com/sxp/i/${this.cheqTagScript.scriptFileName}.js`,
        'data-ch': this.language.domain,
        'data-jsonp': 'onCheqResponse',
        class: `ct_clicktrue_${this.cheqTagScript.clickId}`,
        async: true,
      };

      script.push(tagAttributes);
    }
    return {
      script,
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
    cheqTagScript() {
      const { domain } = this.language;
      return this.$store.state.cheqTag.list.find((cl) => cl.domain === domain);
    },
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

* {
  -webkit-font-smoothing: antialiased;
}

.skew {
  transform: skew(0deg, -1deg);
}

.unskew {
  transform: skew(0deg, 1deg);
}

.skew,
.unskew {
  backface-visibility: hidden;
}

.caret {
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
</style>
