<template>
  <div>
    <PageNotFound v-if="error.statusCode === 404" />
  </div>
</template>
<script>
const PageNotFound = () => import('../components/page-not-found/Index.vue');

export default {
  name: 'Error',
  components: {
    PageNotFound,
  },
  layout: 'search',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
};
</script>
