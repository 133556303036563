<template>
  <div class="opacity-50 lg:opacity-100">
    <img :src="`${$paths.assetsUrl}img/worldmap.svg`" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
